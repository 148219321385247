import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { states } from 'data/location';
import { useStore } from 'store';
import GooglePlacesInput from 'components/placeinput';
import Select from 'react-select';

const Location = () => {
  const {
    UserStore: {
      userAddress,
      updateUserAddress,
      getUserAddress,
      isLoading,
      newBusiness,
      isNewBusiness,
      setNewBusinessLocationInfo,
      changeOnboardingStep,
      createBusiness,
      userInfo
    },
    AuthStore: { user },

  } = useStore();
  const values = useMemo(
    () => ({
      state: '',
      city: '',
      street: '',
      landmark: '',
      longitude: '',
      latitude: '',
    }),
    []
  );

  const errorValue = useMemo(
    () => ({
      selectedStateError: false,
      cityError: false,
      streetError: false,
      landmarkError: false
    }),
    []
  );

  const [cityMenuIsOpen, setCityMenuIsOpen] = useState(false);
  const [stateValue, setState] = useState(values);
  const [error, setError] = useState(errorValue);

  const { selectedStateError, cityError, streetError, landmarkError } = error;

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.currentTarget;
    setState((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleErrors = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setError((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSubmission = useCallback(
    async (e: React.FormEvent) => {
      try {
        e.preventDefault();
        const { state, city, street, landmark } = stateValue;

        if (!state) {
          setError((prev) => ({ ...prev, selectedStateError: true }));
          return;
        }
        if (!city) {
          setError((prev) => ({ ...prev, cityError: true }));
          return;
        }
        if (!street) {
          setError((prev) => ({ ...prev, streetError: true }));
          return;
        }
        if (!landmark) {
          setError((prev) => ({ ...prev, landmarkError: true }));
          return;
        }
        if (!isNewBusiness) {
          updateUserAddress(
            {
              ...stateValue,
              ...userInfo,
              email: user?.email
            },
            true
          );
        } else {
          setNewBusinessLocationInfo({
            ...(stateValue as IAddress)
          });
          await createBusiness(newBusiness);
        }
      } catch (error: any) {
        const message = error.response ? error.response.message : 'Error! Something went wrong';
        toast.error(message, { position: 'top-right' });
      }
    },
    [stateValue, updateUserAddress]
  );

  const toSkip = useCallback(() => {
    changeOnboardingStep('add');
  }, [changeOnboardingStep]);

  const handlePrevious = useCallback(() => {
    changeOnboardingStep('remove');
  }, [changeOnboardingStep]);

  useEffect(() => {
    if (userAddress) {
      setState((prev) => ({ ...prev, ...userAddress }));
    }
  }, [userAddress]);

  useEffect(() => {
    getUserAddress();
  }, [getUserAddress]);

  const selectedState = states.find((state) => state.value === stateValue.state);
  const cityOptions = selectedState?.cities?.map((city) => ({
    label: city.name,
    value: city.value,
  })) || [];

  return (
    <Box>
      <form
        autoComplete="off"
        onSubmit={handleSubmission}
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem'
        }}
      >
        <GooglePlacesInput
          defaultValue={stateValue.street}
          error={streetError}
          handleChange={handleChange}
        />
        <HStack>
          <FormControl my={1}>
            <FormLabel fontWeight="500" fontSize={{ base: '14px', md: '16px' }} aria-label="state">
              State
            </FormLabel>
            <Select
              id="state"
              placeholder={'All locations'}
              name="state"
              value={selectedState ? { label: selectedState.name, value: stateValue.state } : null}
              options={states?.map((state) => ({
                label: state.name,
                value: state.value,
              }))}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setState((prev) => ({ ...prev, state: selectedOption.value, city: '' })); // Reset city when state changes
                } else {
                  setState((prev) => ({ ...prev, state: '', city: '' })); // Reset both state and city if cleared
                }
              }}
              aria-label="state"
              isClearable={true}
            />
            {selectedStateError && (
              <FormLabel fontSize="12px" color="red">
                State is required
              </FormLabel>
            )}
          </FormControl>

          <FormControl>
            <FormLabel fontSize="14px" fontWeight="500">
              City
            </FormLabel>
            {stateValue.state !== 'Lagos' ? (
              <Input
                id="city"
                name="city"
                disabled={!stateValue.state}
                value={stateValue.city || ''}
                onChange={handleChange}
                type="text"
                fontSize={{ base: '1rem', md: '0.875rem' }}
                aria-label="City"
              />
            ) : (
                <Select
                  id="city"
                  placeholder={cityMenuIsOpen ? 'Search cities' : 'Select city'}
                  name="city"
                  options={cityOptions}
                  value={cityOptions.find((city) => city.value === stateValue.city) || null}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setState((prev) => ({ ...prev, city: selectedOption.value }));
                    } else {
                      setState((prev) => ({ ...prev, city: '' })); // Reset city if cleared
                    }
                  }}
                  aria-label="city"
                  onMenuOpen={() => setCityMenuIsOpen(true)}
                  onMenuClose={() => setCityMenuIsOpen(false)}
                  isClearable={true}
                />
            )}
            {cityError && (
              <Text fontSize="12px" color="red">
                City is required
              </Text>
            )}
          </FormControl>
        </HStack>

        <FormControl my={1}>
          <FormLabel fontWeight="500" fontSize={{ base: '14px', md: '16px' }} aria-label="landmark">
            Landmark
          </FormLabel>
          <Input
            id="landmark"
            name="landmark"
            value={stateValue.landmark || ''}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              handleChange(e);
              handleErrors(e);
            }}
            fontSize={{ base: '1rem', md: '0.875rem' }}
            bgColor="#F3F5F7"
            type="text"
            placeholder="Enter a location near you"
            aria-label="landmark"
          />
          {landmarkError && (
            <Text fontSize="12px" color="red">
              Landmark is required
            </Text>
          )}
        </FormControl>

        {!newBusiness ? (
          <Flex justify="space-between">
            <Button
              size="sm"
              bg="primary.sub"
              color="primary.main"
              onClick={handlePrevious}
              aria-label="prev"
            >
              Prev
            </Button>
            <Button
              size="sm"
              variant="outline"
              _hover={{
                bg: 'primary.sub',
                color: 'primary.main'
              }}
              onClick={toSkip}
              aria-label="skip"
            >
              Skip
            </Button>
            <Button
              type="submit"
              size="sm"
              isLoading={isLoading.updateUserAddress}
              aria-label="next"
            >
              Next
            </Button>
          </Flex>
        ) : (
          <Flex justify="end">
            <Button
              type="submit"
              size="sm"
              isLoading={isLoading.updateUserAddress}
              aria-label="next"
            >
              Next
            </Button>
          </Flex>
        )}
      </form>
    </Box>
  );
};

export default observer(Location);
