import { client } from '..';
import config from '../../config';

export const getAllPharmacies = async (params: {
  pageNumber: number;
  pageSize: number;
  searchKey?: string;
  status?: string;
 
}): Promise<ApiResponse<IPaginatedData | null> | undefined> => {
  const { searchKey, status, pageNumber, pageSize } = params;
  return await client<ApiResponse<IPaginatedData | null>>(
    `${config.AUTH_BASE_URL}/api/pharmacy?page=${pageNumber ?? 1}&limit=${
      pageSize ?? 10
    }&searchKey=${searchKey ?? ''}&status=${status ?? ''}`,
    'GET'
  );
};
export const getPharmOrderRequests = async (
  params: GetAllUsersTypes & { pharmacyId: string }
): Promise<ApiResponse<Record<string, any>> | undefined> => {
  const { pharmacyId, page, pageSize, searchKey, startDate, endDate } = params;
  return await client<ApiResponse<Record<string, any>>>(
    `${
      config.AUTH_BASE_URL
    }/api/pharmacy/${pharmacyId}/order-requests?page=${page}&size=${pageSize}&startDate=${
      startDate
    }&endDate=${endDate}&searchKey=${searchKey ?? ''}`,
    'GET'
  );
};

export const getPharmStats = async (
  params: GetAllUsersTypes & { pharmacyId: string }
): Promise<ApiResponse<Record<string, any>> | undefined> => {
  const { pharmacyId, startDate, endDate } = params;
  return await client<ApiResponse<Record<string, any>>>(
    `${
      config.AUTH_BASE_URL
    }/api/pharmacy/${pharmacyId}/stats?startDate=${
      startDate
    }&endDate=${endDate}`,
    'GET'
  );
};
