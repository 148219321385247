import { Box, BoxProps, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { SlOptions } from 'react-icons/sl';
import { parseAmount, formatDateTime } from 'utils';
import { useStore } from 'store';
import Permissions from 'utils/permissions';
import { useRunners } from 'hooks/queryHooks';
import { useMemo } from 'react';

const CreatedBy = (data: any) => {
  const { data: rowData } = data;
  const { data: runners } = useRunners({
    searchKey: '',
    role: '',
    pageNumber: 0,
    pageSize: 100
  });

  const findRunnerById = (id: string) =>
    runners?.userData?.find((runner: IRunners) => runner?.userId === id);

  const getRunnerNameById = (id: string) => {
    const runner = findRunnerById(id) as any;
    return runner?.personalInformation?.firstName || 'N/A';
  };

  const runnerName = useMemo(
    () => getRunnerNameById(rowData?.createdBy),
    [rowData?.createdBy, runners]
  );

  return <Box>{runnerName}</Box>;
};
const InventoryMenuOptions = (data: any) => {
  const { data: rowData } = data;
  const {
    InventoryStore: { toggleViewItemSummaryModal, toggleViewItemImages, toggleDeleteMedModal },
    AuthStore
  } = useStore();
  const permissions = new Permissions(AuthStore);

  return (
    <Box>
      <Menu>
        <MenuButton>
          <SlOptions />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => toggleViewItemSummaryModal(rowData?._id)}>
            View item details
          </MenuItem>
          <MenuItem onClick={() => toggleViewItemImages(rowData?._id)}>View item images</MenuItem>
          {permissions.hasFeatureAccess('DeleteProducts') && (
            <MenuItem onClick={() => toggleDeleteMedModal(rowData?._id)}>Delete item</MenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};

export const adminInventoryColumn = [
  {
    key: 'drugGeneratedId',
    label: 'item id',
    headerStyle: { textAlign: 'center' } as BoxProps,
    chakraStyle: { color: '#541AA9', textAlign: 'center' } as BoxProps
  },
  {
    key: 'brandName',
    label: 'brand name',
    notFoundText: 'N/A',
    headerStyle: { textAlign: 'center' } as BoxProps,
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'strength',
    label: 'strength',
    notFoundText: 'N/A',
    headerStyle: { textAlign: 'center' } as BoxProps,
    chakraStyle: { textAlign: 'center' } as BoxProps,
    formatter: (value: string) => (value ? value : '-')
  },
  {
    key: 'manufacturer',
    label: 'manufacturer',
    notFoundText: 'N/A',
    headerStyle: { textAlign: 'center' } as BoxProps,
    chakraStyle: { textAlign: 'center' } as BoxProps,
    formatter: (value: string) => (value ? value : 'N/A')
  },
  {
    key: 'category',
    label: 'category',
    notFoundText: 'N/A',
    headerStyle: { textAlign: 'center' } as BoxProps,
    chakraStyle: { textAlign: 'center' } as BoxProps,
    formatter: (value: string) => {
      return (
        <Text sx={{ bg: '#F2E9FF', color: '#541AA9', p: '0.6rem', borderRadius: '8px', w: '100%' }}>
          {value.toUpperCase()}
        </Text>
      );
    }
  },
  {
    key: 'dosageForm',
    label: 'dosage form',
    headerStyle: { textAlign: 'center' } as BoxProps,
    notFoundText: 'N/A',
    chakraStyle: { textAlign: 'center' } as BoxProps,
    formatter: (value: string) => {
      return (
        <Text sx={{ bg: '#FFF8DF', color: '#FBBF00', p: '0.6rem', borderRadius: '8px', w: '100%' }}>
          {value.toUpperCase()}
        </Text>
      );
    }
  },
  {
    key: 'unit',
    label: 'unit',
    headerStyle: { textAlign: 'center' } as BoxProps,
    chakraStyle: { textAlign: 'center' } as BoxProps,
    formatter: (value: string, data: any) => {
      return (
        <Text sx={{ bg: '#FEE1F9', color: '#DC3CBF', p: '0.6rem', borderRadius: '8px', w: 'auto' }}>
          {data.unit.join(' | ').toUpperCase()}
        </Text>
      );
    }
  },
  {
    key: 'costPrice',
    label: 'c.p',
    notFoundText: 'N/A',
    headerStyle: { textAlign: 'center' } as BoxProps,
    formatter: (value: number) => parseAmount(value),
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'price',
    label: 's.p',
    notFoundText: 'N/A',
    headerStyle: { textAlign: 'center' } as BoxProps,
    formatter: (value: number) => parseAmount(value),
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: 'createdAt',
    label: 'date created',
    headerStyle: { textAlign: 'center' } as BoxProps,
    formatter: (value: string) => {
      const date = formatDateTime(value);
      return <div style={{ width: '100px' }}>{date}</div>;
    },
    chakraStyle: { textAlign: 'center' } as BoxProps
  },
  {
    key: '_id',
    label: '',
    formatter: (value: string, rowData: any) => {
      return <InventoryMenuOptions data={rowData} />;
    },

    chakraStyle: { textAlign: 'center' } as BoxProps
  }
];
export const adminInventoryActivities = [
  {
    key: 'activityType',
    label: 'Activity',
    headerStyle: { textAlign: 'start', textTransform: 'capitalize', fontSize: '14px' } as BoxProps,
    chakraStyle: { fontWeight: 'bold', textAlign: 'start' } as BoxProps
  },
  {
    key: 'description',
    label: 'Description',
    headerStyle: { textAlign: 'start', textTransform: 'capitalize', fontSize: '14px' } as BoxProps,
    formatter: (value: string) => {
      return <div>{value}</div>;
    },
    chakraStyle: { textAlign: 'start' } as BoxProps
  },
  {
    key: 'createdBy',
    label: 'Runner',
    headerStyle: { textAlign: 'start', textTransform: 'capitalize', fontSize: '14px' } as BoxProps,
    formatter: (value: string, rowData: any) => {
      return <CreatedBy data={rowData} />;
    },
    chakraStyle: { textAlign: 'start' } as BoxProps
  },
  {
    key: 'createdAt',
    label: 'Date',
    headerStyle: { textAlign: 'start', textTransform: 'capitalize', fontSize: '14px' } as BoxProps,
    formatter: (value: string) => {
      const date = formatDateTime(value);
      return <div>{date}</div>;
    },
    chakraStyle: { textAlign: 'start' } as BoxProps
  }
];
export const categoryOptions: { value: string; label: string }[] = [
  { value: 'MultiVitamin', label: 'Multivitamin' },
  { value: 'Multivitamins & Supplements', label: 'Multivitamins & Supplements' },
  { value: 'Antibiotic', label: 'Antibiotic' },
  { value: 'Medical devices', label: 'Medical devices' },
  { value: 'Antiviral', label: 'Antiviral' },
  { value: 'Skincare', label: 'Skincare' },
  { value: 'Antifungal', label: 'Antifungal' },
  { value: 'Sexual Aid', label: 'Sexual Aid' },
  { value: 'Hormones', label: 'Hormones' },
  { value: 'Antiasthma', label: 'Antiasthma' },
  { value: 'Antiseptic', label: 'Antiseptic' },
  { value: 'Antimalarial', label: 'Antimalarial' },
  { value: 'Antidepressant', label: 'Antidepressant' },
  { value: 'Antiarrhythmic', label: 'Antiarrhythmic' },
  { value: 'Cough and cold', label: 'Cough And cold' },
  { value: 'Cough and Cold', label: 'Cough And Cold' },
  { value: 'Anaesthetic', label: 'Anaesthetic' },
  { value: 'Git', label: 'Git' },
  { value: 'Infusion', label: 'Infusion' },
  { value: 'Anticoagulant', label: 'Anticoagulant' },
  { value: 'Anticholesterol', label: 'Anticholesterol' },
  { value: 'Anti-biotics', label: 'Anti-biotics' },
  { value: 'Anticholinergic', label: 'Anticholinergic' },
  { value: 'Antixiolytics', label: 'Antixiolytics' },
  { value: 'Antiseptics', label: 'Antiseptics' },
  { value: 'Immunosuppressant', label: 'Immunosuppressant' },
  { value: 'Anticonvulsant', label: 'Anticonvulsant' },
  { value: 'Antipakinsonism', label: 'Antipakinsonism' },
  { value: 'Anti fungal', label: 'Anti fungal' },
  { value: 'Anti cholesterol', label: 'Anti cholesterol' },
  { value: 'Anti diuretics', label: 'Anti diuretics' },
  { value: 'Antihaemorrhoids', label: 'Antihaemorrhoids' },
  { value: 'Steroids ', label: 'Steroids ' },
  { value: 'Consumable', label: 'Consumable' },
  { value: 'Antitumor', label: 'Antitumor' },
  { value: 'Sweetener', label: 'Sweetener' },
  { value: 'Supplement', label: 'Supplement' },
  { value: 'Anticancer', label: 'Anticancer' },
  { value: 'Alternative Medicine', label: 'Alternative Medicine' },
  { value: 'Nasal Decongestant', label: 'Nasal Decongestant' },
  { value: 'Cold And Flu', label: 'Cold And Flu' },
  { value: 'Anti-Infective', label: 'Anti-Infective' },
  { value: 'Vaccine', label: 'Vaccine' },
  { value: 'Antifibrinolytic', label: 'Antifibrinolytic' },
  { value: 'Antihelmethics', label: 'Antihelmethics' },
  { value: 'Antispasmodic', label: 'Antispasmodic' },
  { value: 'Anti-gout', label: 'Anti-gout' },
  { value: 'Antipsychotic', label: 'Antipsychotic' },
  { value: 'Personal care', label: 'Personal care' },
  { value: 'Antigout', label: 'Antigout' },
  { value: 'Steroid ', label: 'Steroid ' },
  { value: 'Anti-Infectives', label: 'Anti-Infectives' },
  { value: 'Pain Management', label: 'Pain Management' },
  { value: 'Electronic', label: 'Electronic' },
  { value: 'Cough Medication', label: 'Cough Medication' },
  { value: 'Cold and flu', label: 'Cold and Flu' },
  { value: 'Fertility', label: 'Fertility Supplement' },
  { value: 'Antihistamines', label: 'Antihistamines' },
  { value: 'Accessories', label: 'Accessories' },
  { value: 'Medical Aids/ Accessories', label: 'Medical Aids/ Accessories' },
  { value: 'Antihypertensive', label: 'Antihypertensive' },
  { value: 'Anti Thyroid', label: 'Anti Thyroid/Anti Convulsants' },
  { value: 'Eyes/Ear/Nasal Preps', label: 'Eyes/Ear/Nasal Preps' },
  { value: 'Antidiabetics', label: 'Antidiabetics' },
  { value: 'Antimalaria', label: 'Antimalaria' },
  { value: 'Git', label: 'Git' },
  { value: 'Anti Asthmatics', label: 'Anti Asthmatics/Steroids' },
  { value: 'Tea', label: 'Tea' },
  { value: 'Cleansers', label: 'Cleansers' },
  { value: 'Oral Hygiene', label: 'Oral Hygiene' },
  { value: 'Hormones', label: 'Hormones' },
  { value: 'First Aid', label: 'First Aid' },
  { value: 'Injectables', label: 'Injectables' },
  { value: 'Condoms/Lubricants', label: 'Condoms/Lubricants' },
  { value: 'Lip Therapy', label: 'Lip Therapy' },
  { value: 'Test Kits', label: 'Test Kit' },
  { value: 'Anti Helmethics', label: 'AntiHelmethics' },
  { value: 'Antacids/ Antiulcers ', label: 'Antacids/ Antiulcers' },
  {
    value: 'Pregnancy And Conception',
    label: 'Pregnancy And Conception'
  }
];

export const customerCategoryOptions: { value: string; label: string }[] = [
  {
    value: 'Pregnancy And Conception',
    label: 'Pregnancy And Conception'
  },
  {
    value: 'Diabetes',
    label: 'Diabetes'
  },
  {
    value: 'Cold, Cough And Flu',
    label: 'Cold, Cough And Flu'
  },
  {
    value: 'Hypertension',
    label: 'Hypertension'
  },
  {
    value: 'Asthma & Allergies',
    label: 'Asthma & Allergies'
  },
  {
    value: 'Sexual Health',
    label: 'Sexual Health'
  },
  {
    value: 'Pain & Fever Management',
    label: 'Pain & Fever Management'
  },
  {
    value: 'Skincare',
    label: 'Skincare'
  },
  {
    value: "Men's Health",
    label: "Men's Health"
  },
  {
    value: "Women's Health",
    label: "Women's Health"
  },
  {
    value: 'Wellness',
    label: 'Wellness'
  },
  {
    value: 'Childcare',
    label: 'Childcare'
  },
  {
    value: 'Eye, Ear & Nose Care',
    label: 'Eye, Ear & Nose Care'
  },
  {
    value: 'Dental Care',
    label: 'Dental Care'
  },
  {
    value: 'Malaria & Travel',
    label: 'Malaria & Travel'
  },
  {
    value: 'Antibiotics',
    label: 'Antibiotics'
  },
  {
    value: 'Antifungal',
    label: 'Antifungal'
  },
  {
    value: 'Antiviral',
    label: 'Antiviral'
  },
  {
    value: 'First Aid',
    label: 'First Aid'
  },
  {
    value: 'Dental Health',
    label: 'Dental Health'
  },
  {
    value: 'Medical Devices',
    label: 'Medical Devices'
  },
  {
    value: 'Hospital Consumables',
    label: 'Hospital Consumables'
  },
  {
    value: 'Digestive Health (Ulcer, Diarrhea, Constipation)',
    label: 'Digestive Health (Ulcer, Diarrhea, Constipation)'
  },
  {
    value: 'Antithyroid/Anticonvulsants/Anxiolytics/Antidepressants',
    label: 'Antithyroid/Anticonvulsants/Anxiolytics/Antidepressants'
  },
  {
    value: 'Obesity & Weight Management',
    label: 'Obesity & Weight Management'
  },
  {
    value: 'Hypercholesterolemia',
    label: 'Hypercholesterolemia'
  },
  {
    value: 'Sleep Aid',
    label: 'Sleep Aid'
  },
  {
    value: 'Personal Care',
    label: 'Personal Care'
  },
  {
    value: 'Cleaning & Household Items',
    label: 'Cleaning & Household Items'
  },
  {
    value: 'Others',
    label: 'Others'
  }
];
export const subCategoryOptions: { value: string; label: string }[] = [
  { value: 'Nutrition', label: 'Nutrition' },
  { value: 'Electronic', label: 'Electronic' },
  { value: 'Anti Infectives', label: 'Anti Infectives' },
  { value: 'First Aid', label: 'First Aid' },
  { value: 'Anti Helmethics', label: 'AntiHelmethics' },
  { value: 'Eyes/Ear/Nose', label: 'Eyes/Ear/Nasal Preps' },
  { value: 'MultiVitamin & MonoSupplement', label: 'MultiVitamin Monosupplement' },
  { value: 'Essential Oil', label: 'Essential Oil' },
  { value: 'Hormones', label: 'Hormones' },
  { value: 'Git', label: 'Git' },
  { value: 'Women MultiVitamin', label: 'Women Multivitamin' },
  { value: 'Men MultiVitamin', label: 'Men Multivitamin' },
  { value: 'Children MultiVitamin', label: 'Childres Multivitamin' },
  { value: 'General MultiVitamin', label: 'General Multivitamin' },
  { value: 'Sexual Aid', label: 'Sexual Aid' },
  { value: 'Cough/Cold/Allergies', label: 'Cough/Cold/Allergies' },
  { value: 'Injectables', label: 'Injectables' },
  { value: 'Anti Malaria', label: 'Antimalaria' },
  { value: 'Medical Aids', label: 'Medical Aids/Accesories/Device' },
  { value: 'Antacids', label: 'Antacids/Anti Ulcers' },
  { value: 'AlternativeMedicine', label: 'Alternative Medicine' },
  { value: 'Triple Action Cream', label: 'Triple Action Cream' },
  { value: 'Injectables', label: 'Injectables' },
  { value: 'Diabetics', label: 'Anti Diabetics/Anti Cholesterol' },
  { value: 'Condoms', label: 'Condoms/Lubricants' },
  { value: 'Antacids', label: 'Antacids/Anti Ulcers' },
  { value: 'Anti Asthmatics', label: 'Anti Asthmatics/Steroids' },
  { value: 'Analgesic', label: 'Analgesic/Nsaid/Muscle Relaxant' },
  { value: 'Antihypertensive', label: 'Antihypertensive' },
  { value: 'Anti Histamies', label: 'Antihistamies' },
  { value: 'Anti Thyroid', label: 'Anti Thyroid/Anti Convulsants' }
];

export const dosageFormOptions: { value: string; label: string }[] = [
  { value: 'Gummies', label: 'Gummies' },
  { value: 'Tablet', label: 'Tablet' },
  { value: 'Syrup', label: 'Syrup' },
  { value: 'Capsule', label: 'Capsule' },
  { value: 'Saline', label: 'Saline' },
  { value: 'Bandage', label: 'Bandage' },
  { value: 'Brace', label: 'Brace' },
  { value: 'Needle/Syringe', label: 'Needle/Syringe' },
  { value: 'Gel', label: 'Gel' },
  { value: 'Nebules', label: 'Nebules' },
  { value: 'Eye Drop', label: 'Eye Drop' },
  { value: 'Soft Gel', label: 'Soft Gel' },
  { value: 'Liquid Gel', label: 'Liquid Gel' },
  { value: 'Softgel', label: 'Softgel' },
  { value: 'Powder', label: 'Powder' },
  { value: 'Emulsion', label: 'Emulsion' },
  { value: 'Serum', label: 'Serum' },
  { value: 'Solution', label: 'Solution' },
  { value: 'Solid', label: 'Solid' },
  { value: 'Oil', label: 'Oil' },
  { value: 'Softies', label: 'Softies' },
  { value: 'Kit', label: 'Kit' },
  { value: 'Glove', label: 'Glove' },
  { value: 'Tea', label: 'Tea' },
  { value: 'Liquid', label: 'Liquid' },
  { value: 'Women', label: 'Women' },
  { value: 'Injection', label: 'Injection' },
  { value: 'Jellies', label: 'Jellies' },
  { value: 'Card', label: 'Card' },
  { value: 'Chocolate', label: 'Chocolate' },
  { value: 'Drop', label: 'Drop' },
  { value: 'Ampoule', label: 'Ampoule' },
  { value: 'Drops', label: 'Drops' },
  { value: 'Soft Jelly', label: 'Soft Jelly' },
  { value: 'Ointment', label: 'Ointment' },
  { value: 'Cream', label: 'Cream' },
  { value: 'Bottle', label: 'Bottle' },
  { value: 'Suspension', label: 'Suspension' },
  { value: 'Size', label: 'Size' },
  { value: 'Suppository', label: 'Suppository' },
  { value: 'Suppositories', label: 'Suppositories' },
  { value: 'Spray', label: 'Spray' },
  { value: 'Lotion', label: 'Lotion' },
  { value: 'Pessary', label: 'Pessary' },
  { value: 'Needle', label: 'Needle' },
  { value: 'Condom', label: 'Condom' },
  { value: 'Balm', label: 'Balm' },
  { value: 'Mask', label: 'Mask' },
  { value: 'Needles', label: 'Needles' },
  { value: 'Lozenges', label: 'Lozenges' },
  { value: 'Wash', label: 'Wash' },
  { value: 'Gauze', label: 'Gauze' },
  { value: 'Bandage', label: 'Bandage' },
  { value: 'Solution', label: 'Solution' },
  { value: 'Santizer', label: 'Santizer' },
  { value: 'Rub', label: 'Rub' },
  { value: 'Wool', label: 'Wool' },
  { value: 'Shield', label: 'Shield' },
  { value: 'Inhaler', label: 'Inhaler' },
  { value: 'Pad', label: 'Pad' },
  { value: 'Strip', label: 'Strip' },
  { value: 'Swab', label: 'Swab' },
  { value: 'Support', label: 'Support' },
  { value: 'Lancet', label: 'Lancet' },
  { value: 'Cassette', label: 'Cassette' },
  { value: 'Mouth Wash', label: 'Mouth Wash' },
  { value: 'Bag', label: 'Bag' },
  { value: 'Effervescent', label: 'Effervescent' },
  { value: 'Thermometer', label: 'Thermometer' },
  { value: 'Respirator', label: 'Respirator' },
  { value: 'Gum', label: 'Gum' },
  { value: 'Vial', label: 'Vial' },
  { value: 'Urine Bag', label: 'Urine Bag' },
  { value: 'Cannula', label: 'Cannula' },
  { value: 'Monitor', label: 'Monitor' },
  { value: 'Catheter', label: 'Catheter' },
  { value: 'Suspension', label: 'Suspension' },
  { value: 'Set', label: 'Set' },
  { value: 'Shampoo', label: 'Shampoo' },
  { value: 'Diskus', label: 'Diskus' },
  { value: 'Spray', label: 'Spray' },
  { value: 'Caplet', label: 'Caplet' }
];
export const prescriptionType: { value: string; label: string }[] = [
  { value: 'Acute', label: 'Acute' },
  { value: 'Chronic', label: 'Chronic' }
]
export const supplier: { value: string; label: string }[] = [
  { value: 'PR experience', label: 'PR experience' },
  { value: 'Clafiya', label: 'Clafiya' },
  { value: 'North', label: 'North' },
  { value: 'GUC', label: 'GUC' }
];
export const unitOptions: { value: string; label: string }[] = [
  { value: 'Pack', label: 'Pack' },
  { value: 'Sachet', label: 'Sachet' },
  { value: 'Counting', label: 'Counting' }
];

export const userRoleOption: { value: string; label: string }[] = [
  { value: 'SuperAdmin', label: 'SuperAdmin' },
  { value: 'Administrator', label: 'Administrator' }
];
