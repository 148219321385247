import { Box, Flex, Heading, Image, HStack, Divider } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { Suspense, lazy, useCallback, useEffect } from 'react';
import { cities } from 'data/location';
import { useStore } from 'store';
import backIcon from 'assets/images/backicon.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'constant/index';
import Loading from 'components/loading';
import EmptyCart from 'components/cart/emptycart';
import CartItemTotal from 'components/adminCart/CartItemTotal';
import CartItem from 'components/adminCart/CartItem';
import PartnerReceipientFormDetails from 'components/adminCart/PartnerReceipientFormDetails';
import Permissions from 'utils/permissions';
import Pagination from 'components/pagination';
import CheckBlankIcon from 'components/checkBox/Blank';

const DeliveryAddressCard = lazy(
  () => import('components/receipentform/deliveryaddress/component/DeliveryAddressCard')
);
const PartnerOrderCheckoutAddress = () => {
  const navigate = useNavigate();
  const { id, memberId } = useParams();
  const {
    AppStore: { searchQuery },
    AuthStore: { isAuthenticated },
    CartStore: { updateDeliveryFee, cart },
    UserStore: { selectUserProfile },
    PartnerStore: {
      partnerMembersAdmin,
      getPartnerMembersAdmin,
      setPage,
      page,
      limit,
      partnerMembersCount
    },
    CheckoutStore: {
      deliveryAddress,
      guestAddressForm,
      toggleGuestAddressForm,
      setDeliveryAddress,
      newOrderAddressForm,
      toggleNewOrderAddressForm,
      toggleDeliveryAddressCard,
      addressToEditIndex,
      setAddressToEditIndex,
      selectAddress,
      selectedAddress,
      resetOptions,
      setIsDeliveryAddressCardOpen,
    },
    AuthStore
  } = useStore();
  const permissions = new Permissions(AuthStore);

  const { business } = selectUserProfile as IFullUser;

  useEffect(() => {
    return () => {
      if (newOrderAddressForm || addressToEditIndex !== '') {
        resetOptions();
      }
    };
  }, [newOrderAddressForm, toggleNewOrderAddressForm]);

  useEffect(() => {
    if (memberId) {
      const member = partnerMembersAdmin?.find((member) => member._id === memberId);
      if (member) {
        setDeliveryAddress(member as unknown as IAddress);
        selectAddress(memberId, true);
      }
    } else if (selectUserProfile?.deliveryAddress && selectedAddress === 'default') {
      const userDetails = {
        firstName: business?.businessName,
        lastName: business?.businessName,
        phoneNumber: business?.phoneNumber,
        email: selectUserProfile?.email,
        street: selectUserProfile?.deliveryAddress?.street || '',
        latitude: selectUserProfile?.deliveryAddress?.latitude || '',
        longitude: selectUserProfile?.deliveryAddress?.longitude || '',
        city: selectUserProfile?.deliveryAddress?.city || '',
        state: selectUserProfile?.deliveryAddress?.state || '',
        landmark: selectUserProfile?.deliveryAddress?.landmark || ''
      };
      setDeliveryAddress({ ...userDetails } as unknown as IAddress);
    }
  }, [
    selectUserProfile?.deliveryAddress,
    setDeliveryAddress,
    selectedAddress,
    memberId,
    partnerMembersAdmin
  ]);
  const calculateDeliveryFee = useCallback(
    (orderAddress: IAddress) => {
      if (orderAddress) {
        let fee = 0;
        if (orderAddress?.state?.toLowerCase() === 'lagos') {
          fee = cities.find((x) => x.name === orderAddress?.city)?.price || 0;
        }
        updateDeliveryFee(fee);
      }
    },
    [updateDeliveryFee]
  );

  useEffect(() => {
    if (selectedAddress !== '') {
      calculateDeliveryFee(deliveryAddress);
    }
  }, [calculateDeliveryFee, deliveryAddress, selectedAddress]);

  const handleBackToPartnerMembers = () => {
    if (memberId) {
      navigate(Routes.PartnerMembers(id as string));
    } else {
      navigate(Routes.AdminPartners);
    }
    resetOptions()
  };

  useEffect(() => {
    return () => {
      setIsDeliveryAddressCardOpen(false);
      resetOptions()
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) getPartnerMembersAdmin(id as string);
  }, [ limit, page, searchQuery]);

  return (
    <Flex flexDir="column" mx="2" my="" gap=".5rem">
      <HStack w="100%" justify="space-between" align="center" mt="1.5rem">
        <Flex align="center" gap=".5rem">
          <Image onClick={handleBackToPartnerMembers} src={backIcon} alt="logo" loading="lazy" />
          <Heading fontSize={{ base: '14px', md: '16px' }}>Add delivery address</Heading>
        </Flex>
      </HStack>
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        align="left"
        gap="2.5rem"
        bg="#fff"
        padding="40px 24px 60px"
        marginTop="20px"
        borderRadius="30px"
        position="relative"
      >
        <Box
          px={{ base: '1rem', ml: '1rem', mt: '1rem' }}
          w={{ base: '', md: '90%', lg: '80%' }}
          h="80vh"
          overflowY="scroll"
        >
          <Suspense fallback={<Loading />}>
            {isAuthenticated ? (
              permissions.hasFeatureAccess('CanViewPartnerMembers') && (
                <>
                  {(newOrderAddressForm || addressToEditIndex !== '') && (
                    <PartnerReceipientFormDetails />
                  )}
                  {memberId ? (
                    <>
                      {partnerMembersAdmin
                        ?.filter((member: any) => member?._id === memberId)
                        .map((member: any, index: number) => (
                          <DeliveryAddressCard
                            key={index}
                            handleSelect={() => {
                              selectAddress(member?._id as string, true);
                              setDeliveryAddress(member as unknown as IAddress);
                            }}
                            selected={selectedAddress === (member?._id as string)}
                            street={member?.street || ''}
                            firstName={member?.firstName || ''}
                            lastName={member?.lastName || ''}
                            city={member?.city || ''}
                            state={member?.state || ''}
                            landmark={member?.landmark || ''}
                            isFaf={true}
                            title="Member's Information"
                            onClickHandler={() => {
                              toggleDeliveryAddressCard();
                              setDeliveryAddress(member as unknown as IAddress);
                              setAddressToEditIndex(index.toString());
                              selectAddress(member?._id as string, true);
                            }}
                          />
                        ))}
                    </>
                  ) : (
                    <>
                      {!newOrderAddressForm && (
                        <DeliveryAddressCard
                          selected={selectedAddress === 'default'}
                          handleSelect={() => {
                            selectAddress('default', false);
                            const userDetails = {
                              firstName: business?.businessName,
                              lastName: business?.businessName,
                              phoneNumber: business?.phoneNumber,
                              email: selectUserProfile?.email,
                              street: selectUserProfile?.deliveryAddress?.street || '',
                              latitude: selectUserProfile?.deliveryAddress?.latitude || '',
                              longitude: selectUserProfile?.deliveryAddress?.longitude || '',
                              city: selectUserProfile?.deliveryAddress?.city || '',
                              state: selectUserProfile?.deliveryAddress?.state || '',
                              landmark: selectUserProfile?.deliveryAddress?.landmark || ''
                            };
                            setDeliveryAddress({ ...userDetails } as unknown as IAddress);
                          }}
                          businessName={business?.businessName || ''}
                          street={selectUserProfile?.deliveryAddress?.street || ''}
                          city={selectUserProfile?.deliveryAddress?.city || ''}
                          state={selectUserProfile?.deliveryAddress?.state || ''}
                          landmark={selectUserProfile?.deliveryAddress?.landmark || ''}
                          isFaf={false}
                          title="Partner Address"
                          onClickHandler={() => {
                            toggleDeliveryAddressCard();
                            const userDetails = {
                              firstName: business?.businessName,
                              lastName: business?.businessName,
                              phoneNumber: business?.phoneNumber,
                              email: selectUserProfile?.email,
                              street: selectUserProfile?.deliveryAddress?.street || '',
                              latitude: selectUserProfile?.deliveryAddress?.latitude || '',
                              longitude: selectUserProfile?.deliveryAddress?.longitude || '',
                              city: selectUserProfile?.deliveryAddress?.city || '',
                              state: selectUserProfile?.deliveryAddress?.state || '',
                              landmark: selectUserProfile?.deliveryAddress?.landmark || ''
                            };
                            setDeliveryAddress({ ...userDetails } as unknown as IAddress);
                            setAddressToEditIndex('default');
                          }}
                        />
                      )}
                      {partnerMembersCount < 1 && !newOrderAddressForm
                        ? permissions.hasFeatureAccess('CanAddPartnerMembers') && (
                            <Flex
                              onClick={() => {
                                toggleNewOrderAddressForm();
                              }}
                              alignItems="center"
                              gap="10px"
                              _hover={{
                                color: 'primary.main'
                              }}
                              cursor="pointer"
                            >
                              <CheckBlankIcon />
                              Can't find member? Add new member
                            </Flex>
                          )
                        : partnerMembersAdmin?.map((member, index) => (
                            <DeliveryAddressCard
                              key={index}
                              handleSelect={() => {
                                selectAddress(member?._id as string, true);
                                setDeliveryAddress(member as unknown as IAddress);
                              }}
                              selected={selectedAddress === (member?._id as string)}
                              street={member?.street || ''}
                              firstName={member?.firstName || ''}
                              lastName={member?.lastName || ''}
                              city={member?.city || ''}
                              state={member?.state || ''}
                              landmark={member?.landmark || ''}
                              isFaf={true}
                              title="Member's Information"
                              onClickHandler={() => {
                                toggleDeliveryAddressCard();
                                setDeliveryAddress(member as unknown as IAddress);
                                setAddressToEditIndex(index.toString());
                                selectAddress(member?._id as string, true);
                              }}
                            />
                          ))}
                    </>
                  )}
                </>
              )
            ) : guestAddressForm ? (
              <PartnerReceipientFormDetails />
            ) : (
              <DeliveryAddressCard
                selected={selectedAddress === 'guest'}
                handleSelect={() => {
                  selectAddress('guest', true);
                }}
                street={deliveryAddress.street as string}
                city={deliveryAddress.city as string}
                state={deliveryAddress.state as string}
                landmark={deliveryAddress.landmark as string}
                isFaf={false}
                onClickHandler={() => {
                  toggleGuestAddressForm();
                }}
                title={`${deliveryAddress.firstName} ${deliveryAddress.lastName}`}
                isGuest={true}
              />
            )}
          </Suspense>
        </Box>
        {partnerMembersCount > 1 && !newOrderAddressForm && (
          <Box position="absolute" top="700px" left="20px">
            <Pagination
              currentPage={page}
              totalPages={partnerMembersCount}
              pageLimit={limit}
              onPageChange={setPage}
            />
          </Box>
        )}
        <Suspense fallback={<Loading />}>
          <Box
            border="1px solid #f5f2f2"
            w={{ base: '93%', md: '84%', lg: '60%' }}
            bg="#ffff"
            borderRadius="5px"
            justifyContent="center"
            alignItems="center"
            height="fit-content"
            paddingBottom="20px"
          >
            {cart.length < 1 ? (
              <EmptyCart />
            ) : (
              <>
                {cart.map((item, index) => (
                  <Box key={index}>
                    <CartItem cartItem={item} index={index} />
                    <Divider />
                  </Box>
                ))}

                <CartItemTotal />
              </>
            )}
          </Box>
        </Suspense>
      </Flex>
    </Flex>
  );
};

export default observer(PartnerOrderCheckoutAddress);
