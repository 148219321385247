export const OrderKeyMappping = {
    IS_SUBSCRIPTION: 'isSubscription',
    ORDERTYPE: 'orderType',
    ORDERSTATUS: 'orderStatus',
    SUBSCRIPTIONDATA: 'subscriptionData',
};

export enum DiscountTypes {
    FIXED = 'fixed',
    PERCENTAGE = 'percentage'
}