import * as yup from 'yup';

export const InventoryDetailsSchema = yup.object().shape({
    brandName: yup.string().required('Brand name is required'),
    genericName: yup.string().required('General name is required'),
    popularName: yup.string().required('Popular name is required'),
    manufacturer: yup.string().required('Manufacturer is required'),
    category: yup.string().required('Category is required'),
    medicationSummary: yup.string().required('Medication Summary is required'),
    dosageForm: yup.string().required('Dosage Form is required'),
    costPrice: yup.number()
        .required('Cost price is required')
        .min(0, 'Cost price must be greater than or equal to 0'),
    price: yup.number()
        .when('hidePrice', {
            is: false,
            then: yup.number()
                .required('Selling price is required')
                .moreThan(yup.ref('costPrice'), 'Selling price must be greater than the cost price'),
            otherwise: yup.number().notRequired()
        }),
    unit: yup.array()
        .of(yup.string().required('unit is required'))
        .min(1, 'At least one unit is required'),
});


export const BundleDetailsSchema = yup.object().shape({
    name: yup.string().required('name is required'),
    category: yup.string().required('Category is required'),
    customerCategory: yup.array().of(yup.string()).required('customerCategory is required'),
    description: yup.string().required('description is required'),
    sellingPrice: yup.number().positive('sellingPrice is not valid').required('sellingPrice is required'),
    supplier: yup.string().required('supplier is required'),
    drugs: yup.array().of(yup.string()).required('drugs are required'),
    image: yup.string().required('image is required'),

});