import { flow, makeObservable, observable, action, runInAction } from 'mobx';
import { RootStore } from '..';
import toast from 'react-hot-toast';
import { parseError } from 'utils';
import {
  getAllPharmarunPartners,
  createPartner,
  updatePartner,
  updatePartnerStatus,
  approveCredit,
  remitCredit,
  getPartnerById,
  addPartnerBulkUpload,
  getTopGrossingPartners,
  getPartnerMembersAdmin,
  addPartnerMemberAdmin,
  updatePartnerMemberAdmin,
  deletePartnerMemberAdmin
} from 'requests/partners';
import Alert from 'utils/Alert';

export class PartnerStore {
  partners = [] as IPartner[];
  selectedPartner = {} as IPartner;
  selectedJoinPartner = {} as IPartner;
  selectedPartnerInfo = {} as IPartner;
  pharmacies = [];
  dispatchs = [];
  hmos = [] as IPartner[];
  filteredPartners = [] as IPartner[];
  topGrossingPartners = [] as ITopGrossingPartner[];
  filterPartnerOptions = {
    partnerType: [],
    isDefaulting: [],
    status: []
  } as Record<string, Array<string>>;
  filterPartnerOrderOptions = {
    orderType: [],
    location: [],
    orderStatus: [],
    orderStage: [],
    customerSource: [],
    isLogged: []
  } as Record<string, Array<string>>;
  partnerStats = {
    totalGrossingHMO: 0,
    totalGrossingPharmacy: 0,
    totalGrossingDispatch: 0,
    totalGrossingCommunity: 0,
    totalGrossingBusinessPartner: 0,
    totalGrossingOthers: 0
  };
  partnerCreditStats = {
    totalCreditApproved: 0,
    creditUsed: 0,
    creditRemitted: 0,
    totalOrderValue: 0,
    counterBalance: 0
  };
  isPlacePartnerOrderModalOpen = false;
  isShowAddPartnerMember = false;
  isViewPartnerMember = false;
  isViewPartnerMembers = false;
  isViewPartnerMemberOrders = false;
  partnerMember = {} as IMember;
  partnerMembersAdmin: IMember[] = [];
  isViewPartnerModalOpen = false;
  isAddBranchModalOpen = false;
  isAddPartnerModalOpen = false;
  isStatusActivationModalOpen = false;
  isAddPartnerTypeModalOpen = false;
  isOpenCreditInfoModal = false;
  isOpenWalletInfoModal = false;
  isDeletePartnerOpen = false;
  isViewAddPartnerOption = false;
  isSuspendPartnerOpen = false;
  loading = false;
  isLoading = {
    getAllPartners: false,
    createPartner: false,
    updatePartner: false,
    updatePartnerStatus: false,
    approveCredit: false,
    remitCredit: false,
    getSinglePartner: false,
    fetchTopGrossingPartners: false,
    getPartnerMembersAdmin: false,
    addPartnerMemberAdmin: false,
    updatePartnerMemberAdmin: false,
    DeletePartnerMemberAdmin: false
  };
  isLoadingSaveOrUpdate = false;
  isViewJoinPartnerOpen = false;
  isRemovePartnerMemberState = {
    id: '',
    isOpen: false,
    isMember: false
  };
  isMemberState = {
    isOpen: false,
    isMember: false
  };

  partnerValues = {
    partnerName: '',
    licenseNumber: '',
    firstName: '',
    lastName: '',
    street: '',
    city: '',
    state: '',
    landmark: '',
    longitude: '',
    latitude: '',
    phoneNumber: '',
    email: '',
    bank: '',
    accountNumber: '',
    accountName: '',
    partnerType: '',
    walletWithdrawal: true
  } as IPartner;
  editPartnerValues = '' as string;
  newPartnerValues = '' as string;

  limit = 10;
  page = 1;
  partnerMembersCount = 0;
  currentPage = 1;
  pageLimit = 10;

  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      partners: observable,
      pharmacies: observable,
      dispatchs: observable,
      hmos: observable,
      loading: observable,
      partnerStats: observable,
      partnerCreditStats: observable,
      selectedPartner: observable,
      selectedJoinPartner: observable,
      isPlacePartnerOrderModalOpen: observable,
      isShowAddPartnerMember: observable,
      isViewPartnerMember: observable,
      isViewPartnerMembers: observable,
      isViewPartnerMemberOrders: observable,
      isViewPartnerModalOpen: observable,
      isAddBranchModalOpen: observable,
      isAddPartnerModalOpen: observable,
      partnerMembersAdmin: observable,
      isStatusActivationModalOpen: observable,
      isAddPartnerTypeModalOpen: observable,
      partnerValues: observable,
      editPartnerValues: observable,
      newPartnerValues: observable,
      isOpenCreditInfoModal: observable,
      isOpenWalletInfoModal: observable,
      isLoadingSaveOrUpdate: observable,
      filterPartnerOptions: observable,
      filterPartnerOrderOptions: observable,
      filteredPartners: observable,
      isDeletePartnerOpen: observable,
      isViewJoinPartnerOpen: observable,
      topGrossingPartners: observable,
      isViewAddPartnerOption: observable,
      isSuspendPartnerOpen: observable,
      partnerMember: observable,
      limit: observable,
      page: observable,
      partnerMembersCount: observable,
      currentPage: observable,
      pageLimit: observable,

      calculatePartnerCreditStats: action.bound,
      calculatePartnerStats: action.bound,
      togglePlacePartnerOrderModal: action.bound,
      toggleAddPartnerMember: action.bound,
      toggleViewPartnerMember: action.bound,
      toggleViewPartnerMembers: action.bound,
      toggleViewPartnerModal: action.bound,
      setSelectedPartner: action.bound,
      setSelectedPartnerMember: action.bound,
      toggleViewPartnerMemberOrders: action.bound,
      toggleAddBranchModal: action.bound,
      toggleAddPartnerModal: action.bound,
      toggleStatusActivationModal: action.bound,
      toggleAddPartnerTypeModal: action.bound,
      toggleCreditInfoModal: action.bound,
      toggleWalletInfoModal: action.bound,
      toggleDeletePartnerModal: action.bound,
      togglSuspendPartnerModal: action.bound,
      setPartnerValues: action.bound,
      setEditPartnerValues: action.bound,
      resetFormAndLoadingState: action.bound,
      handleSaveOrUpdate: action.bound,
      toggleRemoveMembers: action.bound,
      setFilteredPartners: action.bound,
      setFilterOptionsData: action.bound,
      setAllFilterOptionsData: action.bound,
      filterPartners: action.bound,
      resetFilterOptions: action.bound,
      setAllFilterPartnerOrderOptions: action.bound,
      setFilterPartnerOrderOptions: action.bound,
      resetfilterPartnerOrderOptions: action.bound,
      toggleViewJoinPartnerModal: action.bound,
      toggleAddPartnerOption: action.bound,
      getFilteredPharmacies: action.bound,
      setLimit: action.bound,
      setPage: action.bound,
      resetPartnerLocation: action.bound,
      setPartnerLocation: action.bound,
      setCurrentPage: action.bound,
      setPageLimit: action.bound,

      getAllPartners: flow.bound,
      createPartner: flow.bound,
      updatePartner: flow.bound,
      updatePartnerStatus: flow.bound,
      approveCredit: flow.bound,
      remitCredit: flow.bound,
      getSinglePartner: flow.bound,
      fetchTopGrossingPartners: flow.bound,
      addPartnerBulk: flow.bound,
      getPartnerMembersAdmin: flow.bound,
      addPartnerMemberAdmin: flow.bound,
      updatePartnerMemberAdmin: flow.bound,
      deletePartnerMemberAdmin: flow.bound
    });

    this.rootStore = rootStore;
  }
  resetFormAndLoadingState = () => {
    this.partnerValues = {
      partnerName: '',
      licenseNumber: '',
      firstName: '',
      lastName: '',
      street: '',
      city: '',
      state: '',
      landmark: '',
      longitude: '',
      latitude: '',
      phoneNumber: '',
      email: '',
      bank: '',
      accountNumber: '',
      accountName: '',
      partnerType: ''
    };
    this.isLoadingSaveOrUpdate = false;
  };

  setNewPartnerValues = (values: string) => {
    this.newPartnerValues = values;
  };

  setEditPartnerValues = (partnerId: string) => {
    this.editPartnerValues = partnerId as string;
  };

  setPartnerValues = (values: IPartner) => {
    this.partnerValues = values;
  };

  toggleWalletInfoModal = (partnerId: string, partner?: IPartner) => {
    this.selectedPartner = partner as IPartner;
    this.rootStore.FinanceStore.getWalletById(partnerId);
    this.isOpenWalletInfoModal = !this.isOpenWalletInfoModal;
  };
  toggleCreditInfoModal = (partnerId: string, partner?: IPartner) => {
    this.selectedPartner = partner as IPartner;
    this.rootStore.FinanceStore.getWalletById(partnerId);
    this.isOpenCreditInfoModal = !this.isOpenCreditInfoModal;
  };
  handleSaveOrUpdate = async (values: IPartner, isNewPartner: boolean) => {
    this.isLoadingSaveOrUpdate = true;
    try {
      const newValues = {
        partnerName: values.partnerName,
        licenseNumber: values.licenseNumber,
        firstName: values.firstName,
        lastName: values.lastName,
        street: values.street,
        city: values.city,
        state: values.state,
        landmark: values.landmark,
        longitude: values.longitude,
        latitude: values.latitude,
        phoneNumber: values.phoneNumber,
        email: values.email,
        bank: values.bank,
        accountNumber: values.accountNumber,
        accountName: values.accountName,
        partnerType: values.partnerType,
        walletWithdrawal: values.walletWithdrawal
      };

      let res;

      if (isNewPartner) {
        res = await this.createPartner(newValues);
      } else {
        res = await this.updatePartner(newValues, this.editPartnerValues as string);
        this.toggleAddPartnerTypeModal(values.partnerType as string);
      }

      if (res) {
        this.toggleAddPartnerTypeModal(values.partnerType as string);
        this.resetFormAndLoadingState();
      }
    } catch (error) {
      toast.error(parseError(error));
    } finally {
      this.isLoadingSaveOrUpdate = false;
    }
  };

  toggleRemoveMembers(data?: { id: string; isMember: boolean; isOpen?: boolean }) {
    if (data) {
      this.isRemovePartnerMemberState = {
        ...data,
        isOpen: true
      };
    } else {
      this.isRemovePartnerMemberState = {
        id: '',
        isOpen: false,
        isMember: false
      };
    }
  }
  toggleViewJoinPartnerModal(id?: string) {
    this.isViewJoinPartnerOpen = !this.isViewJoinPartnerOpen;
    this.selectedJoinPartner = this.partners.find((item: any) => item.partnerId === id) as IPartner;
  }
  toggleAddPartnerOption() {
    this.isViewAddPartnerOption = !this.isViewAddPartnerOption;
  }
  toggleAddPartnerTypeModal = (partnerType: string) => {
    if (!this.isAddPartnerTypeModalOpen) {
      this.setNewPartnerValues('newPartnerValues');
      this.setPartnerValues({
        ...this.partnerValues,
        partnerType
      });
    } else {
      this.setNewPartnerValues('');
      this.setPartnerValues({
        partnerName: '',
        licenseNumber: '',
        firstName: '',
        lastName: '',
        street: '',
        city: '',
        state: '',
        landmark: '',
        longitude: '',
        latitude: '',
        phoneNumber: '',
        email: '',
        bank: '',
        accountNumber: '',
        accountName: '',
        partnerType: '',
        walletWithdrawal: true
      });
    }

    this.isAddPartnerTypeModalOpen = !this.isAddPartnerTypeModalOpen;
    this.isAddPartnerModalOpen = this.isAddPartnerTypeModalOpen; // Keep add and edit modals in sync
    this.isAddPartnerModalOpen = false;
    this.isViewPartnerModalOpen = false;
    this.isPlacePartnerOrderModalOpen = false;
  };

  toggleStatusActivationModal(id: string, partner?: IPartner) {
    this.isStatusActivationModalOpen = !this.isStatusActivationModalOpen;
    this.selectedPartner = partner as IPartner;
  }

  toggleAddPartnerModal = () => {
    this.isAddPartnerModalOpen = !this.isAddPartnerModalOpen;
  };
  toggleAddBranchModal = () => {
    this.isAddBranchModalOpen = !this.isAddBranchModalOpen;
  };

  toggleViewPartnerModal = (id: string, partner?: IPartner) => {
    this.isViewPartnerModalOpen = !this.isViewPartnerModalOpen;
    this.selectedPartner = partner as IPartner;
  };

  toggleViewPartnerMember = (id: any) => {
    this.isViewPartnerMember = !this.isViewPartnerMember;
    if (!id) {
      return;
    }
    this.partnerMember = this.partnerMembersAdmin?.find(
      (member: IMember) => member._id === id
    ) as IMember;
  };

  toggleViewPartnerMembers = () => {
    this.isViewPartnerMembers = !this.isViewPartnerMembers;
  };

  toggleViewPartnerMemberOrders = (allow: boolean) => {
    this.isViewPartnerMemberOrders = allow;
  };

  setSelectedPartner = (id: string) => {
    this.selectedPartner = this.partners?.find(
      (partner: IPartner) => partner.partnerId === id
    ) as IPartner;
  };

  setSelectedPartnerMember = (id: string) => {
    this.partnerMember = this.partnerMembersAdmin?.find(
      (member: IMember) => member._id === id
    ) as IMember;
  };

  togglePlacePartnerOrderModal = (id: string, partner?: IPartner) => {
    this.isPlacePartnerOrderModalOpen = !this.isPlacePartnerOrderModalOpen;
    this.selectedPartner = partner as IPartner;
  };

  toggleAddPartnerMember = (id: any, show: boolean) => {
    this.isShowAddPartnerMember = show;
    if (!id) {
      return;
    } else {
      this.partnerMember = this.partnerMembersAdmin?.find(
        (member: IMember) => member._id === id
      ) as IMember;
    }
  };

  setFilterPartnerOrderOptions = (option: string, id: string) => {
    if (!this.filterPartnerOrderOptions[id]) {
      this.filterPartnerOrderOptions[id] = [];
    }

    if (this.filterPartnerOrderOptions[id].includes(option)) {
      this.filterPartnerOrderOptions[id] = this.filterPartnerOrderOptions[id].filter(
        (item: string) => item !== option
      );
    } else {
      this.filterPartnerOrderOptions[id].push(option);
    }
  };
  setAllFilterPartnerOrderOptions = (option: string, id: string, checked?: boolean) => {
    if (!this.filterPartnerOrderOptions[id]) {
      this.filterPartnerOrderOptions[id] = [];
    }

    if (checked && !this.filterPartnerOrderOptions[id].includes(option)) {
      this.filterPartnerOrderOptions[id].push(option);
    } else {
      this.filterPartnerOrderOptions[id] = [];
    }
  };
  resetfilterPartnerOrderOptions() {
    this.filterPartnerOrderOptions = {
      orderType: [],
      location: [],
      orderStatus: [],
      orderStage: [],
      customerSource: [],
      isLogged: []
    };
  }
  resetPartnerLocation = () => {
    this.filterPartnerOrderOptions.location = [];
    this.setPartnerLocation([]);
  };

  setPartnerLocation(location: string[]) {
    this.filterPartnerOrderOptions.location = location;
  }
  setFilteredPartners(partners: IPartner[]) {
    this.filteredPartners = partners;
  }
  setFilterOptionsData = (option: string, id: string) => {
    if (!this.filterPartnerOptions[id]) {
      this.filterPartnerOptions[id] = [];
    }

    if (this.filterPartnerOptions[id].includes(option)) {
      this.filterPartnerOptions[id] = this.filterPartnerOptions[id].filter(
        (item: string) => item !== option
      );
    } else {
      this.filterPartnerOptions[id].push(option);
    }
  };

  setAllFilterOptionsData = (option: string, id: string, checked?: boolean) => {
    if (!this.filterPartnerOptions[id]) {
      this.filterPartnerOptions[id] = [];
    }

    if (checked && !this.filterPartnerOptions[id].includes(option)) {
      this.filterPartnerOptions[id].push(option);
    } else {
      this.filterPartnerOptions[id] = [];
    }

    this.filterPartners();
  };
  setCurrentPage(page: number) {
    this.currentPage = page;
  }

  setPageLimit(limit: number) {
    this.pageLimit = limit;
  }
  setLimit(limit: number) {
    this.limit = limit;
  }

  setPage(page: number, limit?: number) {
    this.page = page;
    if (limit) {
      this.limit = limit;
    }
  }

  getFilteredPharmacies = (pharm: boolean) => {
    const filteredPartners = pharm
      ? this.filteredPartners.filter(
          (partner: IPartner) => partner.partnerType.toUpperCase() === 'PHARMACY'
        )
      : this.filteredPartners.filter(
          (partner: IPartner) => partner.partnerType.toUpperCase() !== 'PHARMACY'
        );

    return filteredPartners.map((partner) => ({ ...partner }));
  };

  resetFilterOptions() {
    this.filterPartnerOptions = {
      partnerType: [],
      isDefaulting: [],
      status: []
    };
    this.filterPartners();
  }

  filterPartners() {
    const filteredData = this.partners?.filter((partner: IPartner) => {
      const {
        firstName,
        lastName,
        licenseNumber,
        email,
        phoneNumber,
        isDefaulting,
        status,
        partnerType
      } = partner;
      const query = this.rootStore.AppStore.searchQuery?.toLowerCase();
      const defaultStatus = isDefaulting ? 'Defaulters' : 'Non Defaulters';
      const partnerStatus = status === 'Active' ? 'Active' : 'Inactive';

      const isDefaultingMatched =
        this.filterPartnerOptions?.isDefaulting?.length === 0 ||
        this.filterPartnerOptions?.isDefaulting?.includes(defaultStatus);

      const isStatusMatched =
        this.filterPartnerOptions?.status?.length === 0 ||
        this.filterPartnerOptions?.status?.includes(partnerStatus);

      const isTypeMatched =
        this.filterPartnerOptions?.partnerType?.length === 0 ||
        this.filterPartnerOptions?.partnerType?.includes(partnerType.toUpperCase());

      const isQueryMatched =
        firstName?.toLowerCase().includes(query) ||
        lastName?.toLowerCase().includes(query) ||
        licenseNumber?.toLowerCase().includes(query) ||
        phoneNumber?.toLowerCase().includes(query) ||
        email?.toLowerCase().includes(query) ||
        partner?.partnerType?.toLowerCase().includes(query) ||
        partner?.partnerName?.toLowerCase().includes(query) ||
        partner?.partnerId?.toLowerCase().includes(query);

      return isStatusMatched && isTypeMatched && isQueryMatched && isDefaultingMatched;
    });

    this.calculatePartnerStats(filteredData as any);
    this.setFilteredPartners(filteredData as any);
  }

  toggleDeletePartnerModal = (partner?: IPartner) => {
    this.isDeletePartnerOpen = !this.isDeletePartnerOpen;
    if (partner) {
      this.selectedPartner = partner as IPartner;
    }
  };
  togglSuspendPartnerModal = (partner?: IPartner) => {
    this.isSuspendPartnerOpen = !this.isSuspendPartnerOpen;
    if (partner) {
      this.selectedPartner = partner as IPartner;
    }
  };
  calculatePartnerStats = (partners: IPartner[]) => {
    const {
      totalGrossingHMO,
      totalGrossingPharmacy,
      totalGrossingDispatch,
      totalGrossingCommunity,
      totalGrossingBusinessPartner,
      totalGrossingOthers
    } = partners.reduce(
      (
        counts: {
          totalGrossingHMO: number;
          totalGrossingPharmacy: number;
          totalGrossingDispatch: number;
          totalGrossingCommunity: number;
          totalGrossingBusinessPartner: number;
          totalGrossingOthers: number;
        },
        partner: IPartner
      ) => {
        if (partner.partnerType.toUpperCase() === 'HMO') {
          counts.totalGrossingHMO += 1;
        }
        if (partner.partnerType.toUpperCase() === 'PHARMACY') {
          counts.totalGrossingPharmacy += 1;
        }
        if (partner.partnerType.toUpperCase() === 'DISPATCH') {
          counts.totalGrossingDispatch += 1;
        }
        if (partner.partnerType.toUpperCase() === 'COMMUNITY') {
          counts.totalGrossingCommunity += 1;
        }
        if (partner.partnerType.toUpperCase() === 'BUSINESS PARTNER') {
          counts.totalGrossingBusinessPartner += 1;
        }
        if (partner.partnerType.toUpperCase() === 'OTHERS') {
          counts.totalGrossingOthers += 1;
        }
        return counts;
      },
      {
        totalGrossingHMO: 0,
        totalGrossingPharmacy: 0,
        totalGrossingDispatch: 0,
        totalGrossingCommunity: 0,
        totalGrossingBusinessPartner: 0,
        totalGrossingOthers: 0
      }
    );
    this.partnerStats = {
      ...this.partnerStats,
      totalGrossingHMO,
      totalGrossingPharmacy,
      totalGrossingDispatch,
      totalGrossingCommunity,
      totalGrossingBusinessPartner,
      totalGrossingOthers
    };
  };
  calculatePartnerCreditStats(selectedPartnerInfo: IPartner) {
    if (selectedPartnerInfo) {
      const {
        totalCreditApproved,
        creditUsed,
        creditRemitted,
        totalOrderValue,
        creditBalance: counterBalance
      } = selectedPartnerInfo;

      this.partnerCreditStats = {
        totalCreditApproved,
        creditUsed,
        creditRemitted,
        totalOrderValue,
        counterBalance
      } as any;
    }
  }
  *getAllPartners(params: any) {
    this.loading = true;
    try {
      const response = (yield getAllPharmarunPartners(params)) as ApiResponse<IPaginatedData | any>;
      if (response && response.data?.data) {
        const data = response.data.data;
        if (data) {
          runInAction(() => {
            this.partners = data;
            this.pharmacies = data
              .filter((partner: IPartner) => partner.partnerType.toUpperCase() === 'PHARMACY')
              .map((partner: IPartner) => {
                return {
                  value: partner.partnerId,
                  label: partner.partnerName
                };
              });
            this.dispatchs = data
              .filter((partner: IPartner) => partner.partnerType.toUpperCase() === 'DISPATCH')
              .map((partner: IPartner) => {
                return {
                  value: partner.partnerId,
                  label: partner.partnerName
                };
              });
            this.hmos = data
              .filter((partner: IPartner) => partner.partnerType.toUpperCase() === 'HMO')
              .map((partner: IPartner) => {
                return {
                  value: partner.partnerId,
                  label: partner.partnerName
                };
              });
          });
        }
      }
    } catch (error) {
      toast.error(parseError(error));
    } finally {
      this.loading = false;
    }
  }

  *getSinglePartner(partnerId: string | null) {
    try {
      this.isLoading.getSinglePartner = true;
      const response = (yield getPartnerById(partnerId)) as ApiResponse<IPartner[] | any>;
      if (response.status)
      this.setPartnerValues(response.data?.partner);
      this.selectedPartnerInfo = response.data?.partner;
      this.selectedPartner = response.data?.partner;
    } catch (error) {
      toast.error(parseError(error));
    } finally {
      this.isLoading.getSinglePartner = false;
    }
  }

  *updatePartnerStatus(id: string, status: string) {
    this.loading = true;
    try {
      status =
       this.selectedPartner.status === 'Active' || this.selectedPartner.status === 'active'
         ? 'deactivate'
         : 'activate';
      const response = (yield updatePartnerStatus(id, status)) as ApiResponse<string | any>;
      if (response?.data) {
        this.getAllPartners({page:1, pageLimit:10});
        toast.success(`Partner ${status}d  successfully`);
      }
      this.toggleStatusActivationModal(id);
    } catch (error) {
      toast.error(parseError(error));
    } finally {
      this.loading = false;
    }
  }

  *createPartner(data: IPartner) {
    this.isLoading.createPartner = true;
    try {
      const response = (yield createPartner(data)) as ApiResponse<string | any>;
      if (response?.status) {
        this.getAllPartners({ page: 1, pageLimit: 10 });
        toast.success(`${data.partnerType} created successfully`, {
          position: 'top-right'
        });
        return true;
      } else {
        toast.error(response.message);
        return false;
      }
    } catch (error: any) {
      toast.error(parseError(error?.response?.message), {
        position: 'top-right'
      });
    } finally {
      this.isLoading.createPartner = false;
    }
  }
  *addPartnerBulk(file: any) {
    this.isLoading.createPartner = true;
    try {
      const response = (yield addPartnerBulkUpload(file)) as ApiResponse<string | any>;
      if (response?.status === true) {
        this.getAllPartners({ page: 1, pageLimit: 10 });
        toast.success(`${response.message}`, {
          position: 'top-right'
        });
      } else {
        toast.error(parseError(response?.message), {
          position: 'top-right'
        });
      }
    } catch (error: any) {
      toast.error(parseError(error?.response?.message), {
        position: 'top-right'
      });
    } finally {
      this.isLoading.createPartner = false;
    }
  }

  *updatePartner(data: IPartner, partnerId: string) {
    this.isLoading.updatePartner = true;
    try {
      const response = (yield updatePartner(data, partnerId)) as ApiResponse<string | any>;

      if (response?.status === true) {
        this.getAllPartners({ page: 1, pageLimit: 10 });
        toast.success(`${data.partnerType}  updated successfully`, {
          position: 'top-right'
        });
      } else {
        toast.error(response?.message);
      }
    } catch (error: any) {
      toast.error(parseError(error?.response?.message), {
        position: 'top-right'
      });
    } finally {
      this.isLoading.updatePartner = false;
    }
  }

  *approveCredit(partnerId: any, amount: number) {
    this.isLoading.approveCredit = true;
    try {
      const response = (yield approveCredit(partnerId, amount)) as ApiResponse<string | any>;
      if (response?.status === true) {
        this.getAllPartners({ page: 1, pageLimit: 10 });
        toast.success(`${response.message}`, {
          position: 'top-right'
        });
      } else {
        toast.error(`${response.message}`, {
          position: 'top-right'
        });
      }
      this.getSinglePartner(partnerId);
    } catch (error: any) {
      toast.error(parseError(error?.response?.message), {
        position: 'top-right'
      });
    } finally {
      this.isLoading.approveCredit = false;
    }
  }
  *remitCredit(partnerId: string, amount: number) {
    this.isLoading.remitCredit = true;
    try {
      const response = (yield remitCredit(partnerId, amount)) as ApiResponse<string | any>;
      if (response?.status === true) {
        this.getAllPartners({ page: 1, pageLimit: 10 });
        toast.success(`Credit remitted successfully`, {
          position: 'top-right'
        });
      } else {
        toast.error(`${response.message}`, {
          position: 'top-right'
        });
      }
      this.toggleCreditInfoModal(partnerId as string);
    } catch (error: any) {
      toast.error(parseError(error?.response?.message), {
        position: 'top-right'
      });
    } finally {
      this.isLoading.remitCredit = false;
    }
  }
  *fetchTopGrossingPartners(query = '') {
    this.isLoading.fetchTopGrossingPartners = true;
    try {
      const { data } = yield getTopGrossingPartners(query);
      if (data) {
        runInAction(() => {
          this.topGrossingPartners = data;
        });
      }
    } catch (error) {
      Alert.error({ message: parseError(error) });
    } finally {
      this.isLoading.fetchTopGrossingPartners = false;
    }
  }

  *getPartnerMembersAdmin(partnerId: string) {
    this.isLoading.getPartnerMembersAdmin = true;
    try {
      const searchKey = this.rootStore.AppStore.searchQuery;
      const res = (yield getPartnerMembersAdmin({
        partnerId,
        searchKey,
        page: this.page,
        limit: this.limit
      })) as ApiResponse<any>;
      if (res.data) {
        this.partnerMembersAdmin = res.data.members as IMember[];
        this.partnerMembersCount = res.data.count as number;
      }

      return res;
    } catch (error) {
      toast.error(parseError(error), { position: 'top-right' });
    } finally {
      this.isLoading.getPartnerMembersAdmin = false;
    }
  }

  *addPartnerMemberAdmin(body: IMember, partnerId: string) {
    this.isLoading.addPartnerMemberAdmin = true;
    try {
      const res = (yield addPartnerMemberAdmin(body, partnerId)) as ApiResponse<any>;

      this.getPartnerMembersAdmin(partnerId);
      toast.success(`${res.message}`, { position: 'top-right' });
      return res;
    } catch (error) {
      toast.error(parseError(error), { position: 'top-right' });
    } finally {
      this.isLoading.addPartnerMemberAdmin = false;
    }
  }
  *updatePartnerMemberAdmin(body: IMember, id: string, partnerId: string) {
    this.isLoading.updatePartnerMemberAdmin = true;
    try {
      const res = (yield updatePartnerMemberAdmin(body, id, partnerId)) as ApiResponse<any>;

      this.getPartnerMembersAdmin(partnerId);
      toast.success(`${res.message}`, { position: 'top-right' });
      this.partnerMember = {} as IMember;
      this.rootStore.CheckoutStore.deliveryAddress = {
        firstName: '',
        lastName: '',
        email: '',
        street: '',
        city: '',
        state: '',
        phoneNumber: '',
        landmark: '',
        longitude: '',
        latitude: ''
      };
      this.rootStore.CheckoutStore.setAddressToEditIndex('');
      return res;
    } catch (error) {
      toast.error(parseError(error), { position: 'top-right' });
    } finally {
      this.isLoading.updatePartnerMemberAdmin = false;
    }
  }

  *deletePartnerMemberAdmin(id: string, partnerId: string) {
    this.isLoading.DeletePartnerMemberAdmin = true;
    try {
      const res = (yield deletePartnerMemberAdmin(id, partnerId)) as ApiResponse<string>;

      this.getPartnerMembersAdmin(partnerId);
      toast.success(`${res.message}`, { position: 'top-right' });
      this.getPartnerMembersAdmin(partnerId);
      this.toggleRemoveMembers();
    } catch (error) {
      toast.error(parseError(error), { position: 'top-right' });
    } finally {
      this.isLoading.DeletePartnerMemberAdmin = false;
    }
  }
}
